<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="smx2" bodyClass="mb-2">
      <template v-slot:header>
        <fa icon="wand-sparkles" class="map-icon me-2" style="width:25px" />
        Bulk Import / Update Devices
      </template>
      <template v-slot:body>
        <div class="p-1 text-center" style="min-height: 310px">
          <div class="btn-group dt-pillbox me-2 mb-3" role="group" aria-label="Bulk Import Operations">
            <input type="radio" class="btn-check" value="generate" name="import"
              id="import-generate" v-model="importView" autocomplete="off" />
            <label class="btn btn-outline-primary" for="import-generate">&nbsp;Generate CSV Template&nbsp;</label>
            <input type="radio" class="btn-check" value="upload" name="import"
              id="import-upload" v-model="importView" autocomplete="off" />
            <label class="btn btn-outline-primary" for="import-upload">&nbsp;Import from File&nbsp;</label>
            <input type="radio" class="btn-check" value="paste" name="import"
              id="import-paste" v-model="importView" autocomplete="off" />
            <label class="btn btn-outline-primary" for="import-paste">&nbsp;Import from Clipboard&nbsp;</label>
            <input type="radio" class="btn-check" value="tips" name="import"
              id="import-tips" v-model="importView" autocomplete="off" />
            <label class="btn btn-outline-primary" for="import-tips">&nbsp;Tips &amp; Tricks&nbsp;</label>

          </div>

          <hr class="form-sep" />

          <div v-if="importView === 'generate'" class="mt-4">
            <div style="position:absolute; top: 275px; left: 45px;">
              <a class="normal padded font-reduce-1" @click="selectAll">Select All Fields</a>
              <span style="margin-right:8px;">|</span>
              <a class="normal padded font-reduce-1" @click="deselectAll">De-select All Fields</a>
            </div>
            <div class="mb-3 pb-1">
              Select the fields you can provide for the import and press Download:
            </div>
            <table class="mt-3" style="margin:auto">
              <tr style="vertical-align:top">
                <td>
                  <CheckboxInputRow label=""
                    checkboxLabel="Dev EUI"
                    id="cbTemplateDevEUI"
                    v-model="alwaysTrue"
                    :disabled="true"
                    class="pb-2" labelClass="width-0"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.make"
                    checkboxLabel="Make"
                    id="cbTemplateMake"
                    class="pb-2" labelClass="width-0"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.model"
                    checkboxLabel="Model"
                    id="cbTemplateModel"
                    class="pb-2" labelClass="width-0"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.serial"
                    checkboxLabel="Serial #"
                    id="cbTemplateSerial"
                    class="pb-2" labelClass="width-0"
                  />
                </td>

                <td>
                  <CheckboxInputRow label=""
                    v-model="fields.status"
                    checkboxLabel="Status"
                    id="cbTemplateStatus"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.site"
                    checkboxLabel="Site"
                    id="cbTemplateSite"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.node"
                    checkboxLabel="Node"
                    id="cbTemplateNode"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.probeDepth"
                    checkboxLabel="Probe Depth"
                    id="cbTemplatePD"
                    class="pb-2" labelClass="width-20"
                  />
                </td>

                <td>
                  <CheckboxInputRow label=""
                    v-model="fields.networkServer"
                    checkboxLabel="LoRa Server"
                    id="cbTemplateLoRaServer"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.networkZone"
                    checkboxLabel="Zone (Senet)"
                    id="cbTemplateNetworkZone"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.applicationKey"
                    checkboxLabel="App Key"
                    id="cbTemplateAppKey"
                    class="pb-2" labelClass="width-20"
                  />
                </td>

                <td>
                  <CheckboxInputRow label=""
                    v-model="fields.hwMonitoring"
                    checkboxLabel="HW Monitoring"
                    id="cbTemplateHWM"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.fwCurrent"
                    checkboxLabel="Loaded FW Ver"
                    id="cbTemplateLFW"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.fwTarget"
                    checkboxLabel="Target FW Ver"
                    id="cbTemplateTFW"
                    class="pb-2" labelClass="width-20"
                  />
                  <CheckboxInputRow label=""
                    v-model="fields.notes"
                    checkboxLabel="Notes"
                    id="cbTemplateNotes"
                    class="pb-2" labelClass="width-20"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div v-if="importView === 'upload' && !vr && !importResult" class="mt-4">
            <span>Attach a CSV file and press Validate to start the import process.</span>

            <FormFieldRow label="" class="justify-content-center mt-4" labelClass="d-none">
              <template v-slot:input>
                <div style="height:32px;margin-top: 3px;">
                  <button class="btn btn-short" v-if="displayFileName === 'Select a File'">
                    <label :title="displayFileName" for="bulkFile">{{ displayFileName }}</label>
                  </button>
                  <div style="height:32px;margin-top: 3px;" v-else>
                    <label class="font-reduce-2 me-3">{{ displayFileName }}</label>
                    <button class="btn btn-short px-2">
                      <label title="Select a File" for="bulkFile">Change</label>
                    </button>
                  </div>
                  <input class="d-none" type="file" id="bulkFile" @change="filesChanged($event)" accept=".csv" />
                </div>
              </template>
            </FormFieldRow>
          </div>
          <div v-if="(importView === 'upload' || importView === 'paste') && importResult" class="mt-2">
            <div v-if="importResult.status">
              <fa icon="check" v-if="importResult.status === 'ok'" class="svg-icon-generic" />
              <fa icon="times" v-if="importResult.status !== 'ok'" class="svg-icon-generic warning" />
              {{ importResult.detail }}
            </div>
            <div v-if="importResult.warnings">
              <ul  style="text-align: left; max-height: 265px; height: 265px; overflow-y: scroll; list-style: none; padding-left: 10px; background-color: var(--form-title-bg-color);" class="add-border">
                <li v-for="(l, i) in importResult.warnings" :key="i" class="status-danger">
                  {{ l }}
                </li>
              </ul>
            </div>
          </div>
          <div v-if="(importView === 'upload' || importView === 'paste') && vr" class="mt-2" style="text-align:left;">
            <div v-if="vr.message && (updateCount > 0 || errorCount > 0)">
              <fa icon="check" v-if="vr.passedValidation" class="svg-icon-generic" />
              <fa icon="times" v-if="vr.passedValidation === false" class="svg-icon-generic status-error" />
              {{ vr.message }}
            </div>
            <div v-if="errorCount > 0 || updateCount > 0">
              <ul  style="text-align: left; max-height: 275px; height: 275px; overflow-y: scroll; overflow-x: clip; list-style: none; padding-left: 10px; background-color: var(--form-title-bg-color); margin-bottom: 0px;" class="add-border">
                <li v-for="(l, i) in validationLines" :key="i" class="font-reduce-2 my-1" :class="{'mt-2': i === 0}">
                  <fa icon="times" v-if="l.startsWith('VALIDATION ERROR')" class="svg-icon-generic status-error" style="margin-top:-2px; margin-right: 3px;" />
                  <fa icon="square-check" v-else-if="l.startsWith('INFO: will ')" class="svg-icon-generic" style="margin-top:-2px; margin-right: 3px;" />
                  <fa icon="minus" v-else class="svg-icon-generic subtle" style="margin-top:-2px; margin-right: 3px;" />
                  {{ l }}
                </li>
              </ul>
            </div>
            <div v-else-if="vr.passedValidation && updateCount == 0" class="ps-3 pt-3">
              <fa icon="check" class="svg-icon-generic subtle" />
              No changes detected between the CSV and the Backend; nothing to import.
            </div>
          </div>
          <div v-if="importView === 'paste' && !vr && !importResult" class="mt-2">
            <div class="mb-2 font-reduce-1">
              Paste CSV or TSV data into this box and press Validate to start the import process:
            </div>
            <TextAreaRow
              inputStyle="width: 700px; height: 265px; white-space: nowrap; font-size:13px;"
              labelClass="d-none" class="pb-0" v-model="csvInput" wrap="off" :disabled="isLoading" />
          </div>
          <div v-if="importView === 'tips'" class="mt-3">
            <div style="text-align: left;" class="ms-3">
              <ul class="font-reduce-1">
                <li class="mt-1">New devices will be created &amp; comissioned. Existing devices will be updated in place.</li>
                <li class="mt-1">Reorder the columns as needed, but column names must match the template.</li>
                <li class="mt-1">Use a cell value of <span class="code-phrase">$NULL</span> to explicitly nullify the value and use any default.</li>
                <li class="mt-1">Use an empty string to retain any existing value or otherwse use any default.</li>
                <li class="mt-1">The default value for <span class="code-phrase">site</span> is <span class="code-phrase">orphaned-devices</span>.</li>
                <li class="mt-1">The default value for <span class="code-phrase">status</span> is <span class="code-phrase">active</span>. Also valid: <span class="code-phrase">inactive</span>.</li>
                <li class="mt-1">The default <span class="code-phrase">hwMonitoring</span> state is <span class="code-phrase">true</span>. Also valid: <span class="code-phrase">false</span>.</li>
                <li class="mt-1">Valid options for <span class="code-phrase">networkServer</span> are: <span class="code-phrase">senet</span>, <span class="code-phrase">loriot-us-1</span>, <span class="code-phrase">aws-iot</span>.</li>
                <li class="mt-1">Valid options for <span class="code-phrase">networkZone</span> are: <span class="code-phrase">private</span>, <span class="code-phrase">public</span> (senet only).</li>
                <li class="mt-1">Required for Senet auto-sync: <span class="code-phrase">networkServer</span>, <span class="code-phrase">networkZone</span>, &amp; <span class="code-phrase">applicationKey</span>.</li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn ms-3" v-if="importView === 'generate'" @click="downloadCSV">Download CSV Template</button>
          <button class="btn ms-3" :disabled="!bulkBase64 || isLoading" v-if="importView === 'upload' && !isFileVerified" @click="validateFileInputCSV">Validate CSV File</button>
          <button class="btn btn-red ms-3" :disabled="!bulkBase64 || isLoading" v-if="importView === 'upload' && isFileVerified && !importResult && vr && vr.passedValidation && updateCount > 0" @click="importFileInputCSV">Import Devices from CSV File</button>
          <button class="btn ms-3" :disabled="isLoading" v-if="importView === 'upload' && (vr || importResult)" @click="vr = null; importResult = null; isFileVerified = false;">Upload a Different File</button>
          <button class="btn btn-red ms-3" :disabled="!csvInput || isLoading" v-if="importView === 'paste' && isPasteVerified && !importResult  && vr && vr.passedValidation && updateCount > 0" @click="importTextInputCSV">Import Devices from Text Data</button>
          <button class="btn ms-3" :disabled="isLoading" v-if="importView === 'paste' && (vr || importResult)" @click="vr = null; importResult = null; isPasteVerified = false;">Re-enter Text Data</button>
          <button class="btn ms-3" :disabled="!csvInput || isLoading" v-if="importView === 'paste' && !isPasteVerified && !vr" @click="validateTextInputCSV">Validate Text Data</button>
          <button class="btn btn-blue ms-3" :disabled="isLoading" @click.prevent="close()">Close</button>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import FormFieldRow from '@/components/forms/FormFieldRow.vue'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      importView: 'generate',
      fields: this.getFields(),
      alwaysTrue: true,
      isFileVerified: false,
      isPasteVerified: false,
      selectedFileName: '',
      bulkBase64: '',
      csvInput: '',
      vr: null,
      importResult: null
    }
  },
  components: { Modal, CheckboxInputRow, FormFieldRow, TextAreaRow },
  methods: {
    applyCSV (input, isBase64 = false, isFromFile = false) {
      if (!input) {
        return
      }
      if (!isBase64) {
        if (input.includes('\t')) {
          input = input.replaceAll(',', ';').replaceAll('\t', ',')
        }
        input = btoa(input)
      }
      this.isLoading = true
      this.vr = null
      this.importResult = null
      const data = {
        data: input
      }
      HelpContent.setFlashMessage('Just a moment. Senet activations and transfers can take up to 30s per-device.', false, true, false, 'hourglass')
      FleetDataService.bulkImportApply(data)
        .then(response => {
          this.importResult = response.data
          if (isFromFile) {
            this.isFileImported = this.importResult &&
              this.importResult.status === 'ok'
          } else {
            this.isPasteImported = this.importResult &&
              this.importResult.status === 'ok'
          }
          HelpContent.dismiss()
        })
        .catch(e => {
          let message = 'Failed to Import the CSV'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validateCSV (input, isBase64 = false, isFromFile = false) {
      if (!input) {
        return
      }
      if (!isBase64) {
        if (input.includes('\t')) {
          input = input.replaceAll(',', ';').replaceAll('\t', ',')
        }
        input = btoa(input)
      }
      this.isLoading = true
      this.vr = null
      this.importResult = null
      const data = {
        data: input
      }
      FleetDataService.bulkImportValidate(data)
        .then(response => {
          this.vr = response.data
          if (isFromFile) {
            this.isFileVerified = this.vr &&
              this.vr.passedValidation
          } else {
            this.isPasteVerified = this.vr &&
              this.vr.passedValidation
          }
        })
        .catch(e => {
          let message = 'Failed to Validate the CSV'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    importTextInputCSV () {
      // this.isFileVerified = false
      // this.isPasteVerified = false
      this.applyCSV(this.csvInput)
    },
    importFileInputCSV () {
      // this.isFileVerified = false
      // this.isPasteVerified = false
      this.applyCSV(this.bulkBase64, true, true)
    },
    validateTextInputCSV () {
      this.isFileVerified = false
      this.isPasteVerified = false
      this.validateCSV(this.csvInput)
    },
    validateFileInputCSV () {
      this.isFileVerified = false
      this.isPasteVerified = false
      this.validateCSV(this.bulkBase64, true, true)
    },
    downloadCSV () {
      const url = window.URL.createObjectURL(new Blob([this.csvText]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'device-import-template.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getFields () {
      return {
        make: false,
        model: false,
        serial: false,
        fwCurrent: false,
        fwTarget: false,
        status: false,
        site: false,
        node: false,
        probeDepth: false,
        networkServer: false,
        networkZone: false,
        applicationKey: false,
        hwMonitoring: false,
        notes: false
      }
    },
    selectAll () {
      this.fields = {
        make: true,
        model: true,
        serial: true,
        fwCurrent: true,
        fwTarget: true,
        status: true,
        site: true,
        node: true,
        probeDepth: true,
        networkServer: true,
        networkZone: true,
        applicationKey: true,
        hwMonitoring: true,
        notes: true
      }
    },
    deselectAll () {
      this.fields = this.getFields()
    },
    show () {
      this.deselectAll()
      this.isBlocking = true
      this.isFileVerified = false
      this.isPasteVerified = false
      this.importResult = null
      this.vr = null
      this.selectedFileName = ''
      this.bulkBase64 = ''
      this.csvInput = ''
    },
    close () {
      this.isBlocking = false
    },
    filesChanged (e) {
      if (!e.target.files || e.target.files.length === 0) {
        this.selectedFileName = ''
        this.bulkBase64 = ''
        return
      }
      const files = e.target.files
      for (const file of files) {
        const reader = new FileReader()
        if (file) {
          reader.addEventListener('load', () => {
            this.selectedFileName = file.name
            this.bulkBase64 = btoa(reader.result)
          }, false)
          reader.readAsText(file)
        }
        break // only care about the first file
      }
    }
  },
  computed: {
    updateCount () {
      if (!this.vr || !this.vr.detail) {
        return 0
      }
      const lines = this.vr.detail.split('|')
      let i = 0
      for (const line of lines) {
        if (line.includes('INFO: will ')) {
          i++
        }
      }
      return i
    },
    errorCount () {
      if (!this.vr || !this.vr.detail) {
        return 0
      }
      const lines = this.vr.detail.split('|')
      let i = 0
      for (const line of lines) {
        if (line.includes('ERROR:')) {
          i++
        }
      }
      return i
    },
    validationLines () {
      if (!this.vr || !this.vr.detail) {
        return []
      }
      return this.vr.detail.split('|')
    },
    displayFileName () {
      if (this.selectedFileName) {
        return this.selectedFileName
      }
      return 'Select a File'
    },
    csvText () {
      let out = 'deviceEui'
      if (this.fields.make) {
        out += ',make'
      }
      if (this.fields.model) {
        out += ',model'
      }
      if (this.fields.serial) {
        out += ',serial'
      }
      if (this.fields.fwCurrent) {
        out += ',fwCurrent'
      }
      if (this.fields.fwTarget) {
        out += ',fwTarget'
      }
      if (this.fields.status) {
        out += ',status'
      }
      if (this.fields.site) {
        out += ',site'
      }
      if (this.fields.node) {
        out += ',node'
      }
      if (this.fields.probeDepth) {
        out += ',probeDepth'
      }
      if (this.fields.networkServer) {
        out += ',networkServer'
      }
      if (this.fields.networkZone) {
        out += ',networkZone'
      }
      if (this.fields.applicationKey) {
        out += ',applicationKey'
      }
      if (this.fields.hwMonitoring) {
        out += ',hwMonitoring'
      }
      if (this.fields.notes) {
        out += ',notes'
      }
      out += '\n\n'
      return out
    }
  }
}
</script>
